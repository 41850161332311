/* Home.css */
*{
  margin: 0;
  /* padding: 0; */
}
::-webkit-scrollbar {
  display: none; 
}
.movies-list {
    list-style: none;
    padding: 0 3rem ;
  }
  
.movie-item {
    margin: 10px;
    display: inline-block;
  }
  
.movie-poster {
    width: 200px;
    cursor: pointer;
    transition: transform 0.3s ease-in-out;
  }
  
.movie-poster:hover {
    transform: scale(1.1);
  }
.searchBar{
    position: absolute;
    top: 2rem;
    right:7.2rem;
}
.searchBar input{
    width: 100%;
    height: 20px;
    border: none;
    background: rgba(200,206,200,0.8);
    padding: 5px;
    border-radius: 10px;
    outline: none;
    font-size: 1rem;
}
.darkMode{
  background-color: #0d0707;
  color: #fff;
}

.lightMode{
  background-color: #fff;
}
.btn{
  position: absolute;
  top: 1rem;
  right: 1rem;
}
@media (max-width : 600px){
  .general{
     display: flex;
     flex-direction: column;
     gap: 10px;
     align-items: center;
     justify-content: center;
     font-size: 2rem;
  }
  .searchBar{
    position: absolute;
    top: 6%;
    left: 30%;
    margin: 10px;
    padding: 10px;
  }
  .searchBar input{
    width: 90%;
    height: 20px;
}
.general p{
  width: 80%;
}
.movies-list {
  /* list-style: none; */
  padding: 0 2rem;
}
.movie-item {
 align-items: center;
}
.movie-poster {
  width: 100px;
  margin: 5px;
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.trailerGeneral{
  width: inherit;
}
iframe{
  width: 90%;
  height: 50%;
}
}